body {
  margin: 0;
  font-family: "Inter",sans-serif;
  width: 100%;
  height: 100%;
}

/* code {
 font-family: 'Public Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
} */
